import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#4F80A8',
        color: 'white',
        py: { xs: 2, sm: 3 },  
        mt: 'auto',
        bottom: 0,
        left: 0,
        width: '100%',
        
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            textAlign: { xs: 'center', sm: 'left' },  // Center text on mobile
          }}
        >
          <Typography 
            variant="body1" 
            sx={{ fontSize: { xs: '14px', sm: '16px' } }}  // Adjust font size
          >
            © {new Date().getFullYear()} Brain4Brains. All rights reserved.
          </Typography>
          <Box sx={{ mt: { xs: 2, sm: 0 } }}>  {/* Add margin top for mobile */}
            <Link href="#" color="inherit" sx={{ mx: { xs: 1, sm: 2 } }}>
              Privacy Policy
            </Link>
            <Link href="#" color="inherit" sx={{ mx: { xs: 1, sm: 2 } }}>
              Terms of Service
            </Link>
            <Link href="/contact" color="inherit" sx={{ mx: { xs: 1, sm: 2 } }}>
              Contact Us
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
