import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
export default function OurStory() {
  return (
    <Box
      sx={{
        backgroundColor: "#F0F4FF",
        minHeight: "85vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px 20px",
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        color="#4F80A8"
        textAlign="center"
        mb={4}
      >
        Our Story
      </Typography>

      <Paper
        elevation={6}
        sx={{
          width: { xs: "100%", md: "80%", lg: "70%" },
          padding: "30px",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          fontSize="1.2rem"
          lineHeight="1.8"
          textAlign="justify"
        >
          <i>
            <span style={{ fontWeight: "bold", color: "#4F80A8" }}>
              Brain4Brains
            </span>{" "}
            was founded with a simple yet powerful mission: to make neuroscience
            education accessible and engaging for teens and children, regardless
            of their socioeconomic background. As a team that is deeply
            passionate about neuroscience, we noticed a gap—scientific
            terminology and complex concepts often make the subject feel out of
            reach for many.
            <br />
            <br />
            <span style={{ fontWeight: "bold", color: "#4F80A8" }}>
              Brain4Brains
            </span>{" "}
            aims to bridge that gap by breaking down dense neuroscience topics
            into easy-to-understand lessons that spark curiosity and a love for
            learning. With brain disorders becoming a leading global health
            concern, nearly 1 in 6 people worldwide suffer from neurological
            conditions ranging from migraines to Alzheimer's disease. As the
            need for young minds to enter the field grows, we provide accessible
            education and resources to inspire the next generation of
            neuroscientists and healthcare professionals.
            <br />
            <br />
            Through workshops, interactive lessons, and engaging content,{" "}
            <span style={{ fontWeight: "bold", color: "#4F80A8" }}>
              Brain4Brains
            </span>{" "}
            strives to make neuroscience fun, relatable, and accessible to all.
            Together, we can unlock the potential of the mind for the benefit of
            all.
          </i>
        </Typography>
      </Paper>
    </Box>
  );
}
