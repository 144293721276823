import React, { useState } from "react";
import { Box, Typography, Card, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faEye, faHeart } from "@fortawesome/free-solid-svg-icons";

const CardContainer = styled(Card)(({ theme }) => ({
  width: "500px",
  margin: "16px",
  padding: "24px",
  backgroundColor: "#F0F4FF",
  borderRadius: "16px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
  },
}));

const Title = styled(Typography)({
  fontFamily: "Urbanist",
  fontWeight: "bold",
  fontSize: "1.8rem",
  color: "#fff",
  textAlign: "center",
  marginBottom: "8px",
});

const IconWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "3rem",
  color: "#fff",
  marginBottom: "16px",
});

const Content = styled(Typography)({
  fontFamily: "Urbanist",
  fontSize: "1rem",
  color: "#fff",
  lineHeight: "1.6",
  textAlign: "center",
});

export default function MissionVisionValues() {
  const [expanded, setExpanded] = useState([false, false, false]);

  const handleExpandClick = (index) => {
    setExpanded((prev) => prev.map((exp, i) => (i === index ? !exp : exp)));
  };

  const cards = [
    {
      title: "MISSION",
      icon: faBullseye,
      content:
        "Our mission is to deliver the latest neuroscience content to educate, inform, and inspire our audience.",
    },
    {
      title: "VISION",
      icon: faEye,
      content:
        "Our vision is to bridge the gap between complex scientific discoveries and the general public, making cutting-edge research accessible and engaging.",
    },
    {
      title: "VALUES",
      icon: faHeart,
      content:
        "We value fostering a deeper understanding of the brain and its functions, promoting curiosity and advancements in the field of neuroscience.",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFF",
        padding: "32px",
      }}
    >
      {cards.map((card, index) => (
        <CardContainer
          key={index}
          onClick={() => handleExpandClick(index)}
          sx={{
            background:
              expanded[index] && index % 2 === 0 ? "#4F80A8" : "#4F80A8",
          }}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={card.icon} />
          </IconWrapper>
          <Title>{card.title}</Title>
          <Collapse in={expanded[index]}>
            <Content>{card.content}</Content>
          </Collapse>
        </CardContainer>
      ))}
    </Box>
  );
}
