// import { createSlice } from "@reduxjs/toolkit";
 
// const initialState = {
//     items:[],
    
// }

// const addCount = createSlice({
//    name:'countSlice',
//    initialState,
//    reducers:{
//     addCountCart:(state) => {
//         state.count+=1
//     }
//    }
// })

// export const {addCountCart} = addCount.actions;
// export default addCount.reducer;


// src/redux/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    items: [],
    isDrawerOpen: false,
    count:0
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
    const existingItem = state.items.find(item => item.id === newItem.id);
    
    if (!existingItem) {
        state.items.push(newItem);
        state.count = state.items.length; // Update the count based on the number of items in the cart
    }
    },
    toggleDrawer: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    removeCart : (state,action)=>{
      state.items.pop(action.payload)
      state.count = state.items.length; // Update count
    }
  },
});

export const { addToCart, toggleDrawer ,removeCart } = cartSlice.actions;

export default cartSlice.reducer;
