import person1 from './assets/person1.jpg'
import person2 from './assets/person2.jpg'
import person3 from './assets/person3.jpg'
import person4 from './assets/person4.jpg'
import person5 from './assets/person5.jpg'
import person6 from './assets/person6.jpg'
import person7 from './assets/person7.jpg'
import person8 from './assets/person8.jpg'
import person9 from './assets/person9.jpg'
import brain1 from './assets/OIP.jpeg'
import brain2 from './assets/brain2.jpeg'
export const pages = ['Home','About Us','Courses','Newsletter','Blog','Help'];
export const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
export const cards = [
    {
      title: 'MISSION',
      color: '#4F80A8',
      content:
        'Our mission is to empower individuals and organizations to achieve their goals through innovative solutions.',
    },
    {
      title: 'VISION',
      color: '#4F80A8',
      content:
        'Our vision is to be a global leader in our industry, recognized for our commitment to quality and customer satisfaction.',
    },
    {
      title: 'VALUES',
      color: '#1A73E8',
      content:
        'We value integrity, excellence, and teamwork, fostering a culture of respect and collaboration.',
    },
  ];

  export const memberinfo = [
    {
      name:'Desika Shyam Sundar',
      designaion:'Founder & CEO',
      about:'Desika is a rising junior at William Mason High School. She is passionate about pursuing a career in the fascinating field of neuroscience as a neurosurgeon, but also aims to close equity gaps in neuroscience education for high schoolers. When she’s not using her brain to learn about itself, Desika loves to read, spend time with her family, and talk to friends',
      image:person1
    },
    {
      name:'Ishanvi Karthikeyan',
      designaion:'Chief Operating Officer',
      about:"Ishanvi is a rising high school senior aspiring to become a clinical geneticist in the field of neurology. She enjoys participating in her school's HOSA Chapter, Pre-Med Club and Academic Quiz Team. Outside of school, Ishanvi plays the violin, sings Indian Carnatic music, and plays Carnatic violin. She enjoys baking and writing in her free time.",
      image:person2
    },
    {
      name:'Layla Das',
      designaion:'Chief Content Officer',
      about:"Layla is a high school junior with both a love for the medical field and writing. She is associated with HOSA, her school’s literary magazine, and Red Cross Club. She loves to write, to play the guitar, and to go bowling. She hopes to go into trauma surgery for her career, while holding great interest in neurology and psychiatry.",
      image:person3
    },
    {
      name:'Madhulika',
      designaion:'Chief Financial Officer',
      about:"Madhulika is dedicated to ensure our financial stability and growth. She enjoys tackling new challenges, whether it's managing finances or exploring creative projects. When she's not crunching numbers, you can find her exploring nature on hikes or indulging in her love for crochet.",
      image:person4
    },
    {
      name:'Alina Zhang',
      designaion:'Social Media Specialist',
      about:"Alina is a rising senior at William Mason High School who aims to have a career in the veterinary field. She loves to be around animals (even if she doesn’t own any herself) and enjoys volunteering at a local animal shelter. Apart from her career interests, she also loves to play the violin and viola, as well as play tennis inside and outside of school.",
      image:person5
    },
    {
      name:'Pranya Vaddadi',
      designaion:'Social Media Specialist',
      about:"Pranya is a junior at Mason High School. She wants to major in Neuroscience in college. She loves learning new things and being involved in different activities at school. She is passionate about Neurology and enjoys helping others and making a positive impact in her community.",
      image:person6
    },
    {
      name:'Sriya Polavarapu',
      designaion:'Research Analyst',
      about:"Sriya is a senior at Mason High School. She is very fascinated by neuroscience and autoimmune diseases. She enjoys painting, dancing, and playing badminton.",
      image:person7
    },
    {
      name:'Plaksha Tambe',
      designaion:'Marketing Manager',
      about:"Plaksha is a junior at Olentangy Orange High School in Columbus, Ohio. She is the Marketing Manager of Brain4Brains. Through this role, she wishes to express her love and passion for Neuroscience. Some fun facts about her are that she has a younger sister in middle school and likes to go on adventure trips and film for her podcast. She can't wait to work with everyone and enhance the reach of our mission",
      image:person8
    },
    {
      name:'Shakthi Sri',
      designaion:'IT Consultant',
      about:"Shakthi is a software engineer at Accenture. She developed the website for Brain4Brains, and is excited to contribute her skills and expertise to this project to make a positive impact. In her free time, she likes to read books, paint, and learn about AI.",
      image:person9
    }
    
  ]

  
  export const predefinedQuestions = [
    'Will finishing the courses give me a certificate?',
    'Are you guys certified to teach neuroscience?',
    'Can I be a part of the leadership team or a committee?',
    'I found an error on your website, where do I report it?',
    'I have a question about something on the blog/newsletter/courses, what should I do?',
  ];

  export const predefinedAnswers = {
    'Will finishing the courses give me a certificate?': 'Yes, upon successful completion of the courses, you will receive a certificate.',
    'Are you guys certified to teach neuroscience?': 'Yes, we are fully certified to teach neuroscience.',
    'Can I be a part of the leadership team or a committee?': 'We welcome enthusiastic members! Please reach out to us for more details.',
    'I found an error on your website, where do I report it?': 'Thank you for noticing! Please report it through our contact form or email us.',
    'I have a question about something on the blog/newsletter/courses, what should I do?': 'Feel free to contact us through the contact form or email us with your question.',
  };

  export const products =[
    {
      id:1,
      author:'Desika',
      hours:5,
      lectures:3,
      level:'Basic',
      title:'Basics of neuroscience',
      price:"Free",
      image:brain1,
      description:'The basics of neuroscience introduce you to fundamental concepts such as the structure and function of neurons, and the basic organization of the brain'
    },
    {
      id:2,
      author:'Desika',
      hours:10,
      lectures:5,
      level:'Advanced',
      title:'Advanced neuroscience',
      price:'Free',
      image:brain2,
      description:'Advanced neuroscience delves into the complex and sophisticated aspects of the nervous system, exploring topics such as neuroplasticity, neural circuits, and brain connectivity. '
    }
  ]

  export const apiBaseUrl = "https://sql.brain4brains.org/"
  export const gradientBackground = {
    background: 'linear-gradient(135deg, #fff, #99ccff)'
  };