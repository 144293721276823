// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     Popup : true,
//     openLogin : true
// }

// const userEntry = createSlice({
//     name: 'userEntry',
//     initialState,
//     isPopupOpen : (state,action) => {
//         state.Popup = action.payload
//     },
//     isLoginOpen : (state,action) => {
//         state.openLogin = action.payload
//     }
// })
// export const {isPopupOpen,isLoginOpen} = userEntry.actions;
// export default userEntry.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Popup: true,
    openLogin: true
};

const userEntry = createSlice({
    name: 'userEntry',
    initialState,
    reducers: {
        isPopupOpen: (state, action) => {
            state.Popup = action.payload;
        },
        isLoginOpen: (state, action) => {
            state.openLogin = action.payload;
        }
    }
});

export const { isPopupOpen, isLoginOpen } = userEntry.actions;
export default userEntry.reducer;
