import React from "react";
import { Typography, Button, Box } from "@mui/material";
import MissionVisionValues from "./Sample";
import BrainIcon from "./BrainIcon";
import main from "../assets/main.jpg";
import { gradientBackground } from "../constants";
export default function Home() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "transparent",
          height: "100vh",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: "center",

          padding: { xs: 2, md: 4 },
          textAlign: { xs: "center", md: "left" },
          position: "relative",
          overflow: "hidden",
          zIndex: 4,
        }}
      >
        {/* Left side text content */}
        <Box
          sx={{
            color: "#fff",
            zIndex: 2,
            maxWidth: { xs: "100%", md: "50%" }, // Ensure text doesn't take too much space
            order: { xs: 2, md: 1 },
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.8rem", md: "3rem" },
              marginBottom: { xs: 2, md: 4 },
              color: "#4F80A8",
            }}
          >
            Welcome to Brain4Brains
          </Typography>
          <a href="#mvv">
            <Button
              variant="contained"
              size="large"
              sx={{
                textTransform: "none",
                padding: "10px 20px",
                backgroundColor: "#4F80A8",
                color: "#fff",
                borderRadius: "50px",
              }}
              endIcon={<span>&rarr;</span>}
            >
              Learn More
            </Button>
          </a>
        </Box>

        {/* Right side brain image */}
        <Box
          component="img"
          src={main}
          alt="Brain Illustration"
          sx={{
            height: { xs: "50vh", md: "70vh" },
            maxWidth: { xs: "100%", md: "50%" }, // Ensures image doesn't overlap text
            order: { xs: 1, md: 2 },
            zIndex: 1,
          }}
        />
      </Box>
      <div id="mvv">
        <MissionVisionValues />
      </div>
    </>
  );
}
