import React, { useState } from "react";
import { Box, Modal, Paper, Tooltip, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContentCopy } from "@mui/icons-material";

// ExpandMore component with animation
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Blog() {
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);

  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };

  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const handleOpenCopyModal = () => {
    setOpen(true);
    setCopied(false);
  };
  const handleCloseCopyModal = () => {
    setOpen(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#F0F4FF",
          color: "white",
          minHeight: "67vh",
          display: "flex",
          justifyContent: "flex-start", // Align horizontally to the left
          alignItems: "flex-start", // Align vertically to the top
          padding: "20px",
          position: "relative",
          gap: "20px", // Space between the cards
          flexWrap: "wrap",
        }}
      >
        {/* First Blog Card */}
        <Card
          sx={{ maxWidth: 550, bgcolor: "white", color: "black", boxShadow: 3 }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                D
              </Avatar>
            }
            title={
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                The Role of Sleep in Memory Consolidation
              </Typography>
            }
            subheader={
              <Typography variant="body2" sx={{ color: "#666" }}>
                August 20, 2024
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="body2" sx={{ color: "#333" }}>
              A recent neuroscience research was conducted to find the role of
              sleep in memory consolidation.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="share" onClick={handleOpenCopyModal}>
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expanded1}
              onClick={handleExpandClick1}
              aria-expanded={expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded1} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph sx={{ color: "#333" }}>
                A study published in “Nature Neuroscience” revealed that during
                stage 3 of sleep, the brain strengthens neural connections,
                transferring information from short-term memory to long-term
                memory.
              </Typography>
              <Typography paragraph sx={{ color: "#333" }}>
                The researchers observed how slow-wave sleep assists this
                process, especially in the hippocampus and neocortex, which are
                key areas for memory storage.
              </Typography>
              <Typography paragraph sx={{ color: "#333" }}>
                Moreover, the quality of sleep matters just as much as the
                quantity. Fragmented or disrupted sleep can impair memory
                retention, which may lead to cognitive decline over time.
              </Typography>
            </CardContent>
          </Collapse>
        </Card>

        {/* Second Blog Card */}
        <Card
          sx={{ maxWidth: 550, bgcolor: "white", color: "black", boxShadow: 3 }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                D
              </Avatar>
            }
            title={
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "black" }}
              >
                The Gut-Brain Connection and Mental Health
              </Typography>
            }
            subheader={
              <Typography variant="body2" sx={{ color: "#666" }}>
                August 20, 2024
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="body2" sx={{ color: "#333" }}>
              Emerging research continues to focus on the complicated
              relationship between the gut and the brain, known as the gut-brain
              axis.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton aria-label="share" onClick={handleOpenCopyModal}>
              <ShareIcon />
            </IconButton>
            <ExpandMore
              expand={expanded2}
              onClick={handleExpandClick2}
              aria-expanded={expanded2}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded2} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph sx={{ color: "#333" }}>
                A recent study from the University of California has found that
                the composition of gut microbiota can significantly influence
                mental health, particularly in relation to mood disorders like
                depression and anxiety. By analyzing the gut bacteria of
                individuals with these conditions, researchers discovered
                specific microbial imbalances that correlate with altered brain
                function.
              </Typography>
              <Typography paragraph sx={{ color: "#333" }}>
                This research suggests that modifying gut bacteria through diet,
                probiotics, or other interventions could become a viable
                strategy for improving mental health. As the understanding of
                the gut-brain connection deepens, it opens new avenues for
                treating psychiatric disorders, highlighting the potential of
                holistic approaches that target both the mind and the body.
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
        <Modal open={open} onClose={handleCloseCopyModal}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: 4,
              width: 300,
            }}
          >
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              Share this blog
            </Typography>
            <TextField
              fullWidth
              value={window.location.href}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Tooltip title="Copy link">
                    <IconButton onClick={handleCopyLink}>
                      <ContentCopy />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />
            {copied && (
              <Typography sx={{ color: "#08024F", marginTop: 2 }}>
                Link copied to clipboard!
              </Typography>
            )}
            <Button
              onClick={handleCloseCopyModal}
              sx={{ marginTop: 3 }}
              variant="contained"
              fullWidth
            >
              Close
            </Button>
          </Paper>
        </Modal>
      </Box>
    </>
  );
}
