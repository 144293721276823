import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./containers/Home.jsx";
import Header from "./components/Header.jsx";
import Footer from "./components/Footer.jsx";
import About from "./containers/About.jsx";
import Contact from "./containers/Contact.jsx";
import Newsletter from "./containers/Newsletter.jsx";
import Chatbot from "./containers/Chatbot.jsx";
import Blog from "./containers/Blog.jsx";
import Courses from "./containers/Courses.jsx";
import CenteredImageBackground from "./containers/SignIn.jsx";
import PaymentComponent from "./components/Pay.jsx";
import { Box } from "@mui/material";
import { gradientBackground } from "./constants.js";
import Ourstory from "./containers/Ourstory.jsx";
function App() {
  const currentRoute = window.location.pathname;
  console.log(currentRoute);
  return (
    <Box >
      <Header route={currentRoute} />

      <Chatbot />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/leadership-panel" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/newsletter" element={<Newsletter />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/our-story" element={<Ourstory/>}></Route>
          <Route path="/courses" element={<Courses />}></Route>
        </Routes>
      </Router>
      <Footer />
    </Box>
  );
}

export default App;
