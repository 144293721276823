import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { Modal, Paper, TextField, Link, InputAdornment } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { apiBaseUrl, pages } from "../constants";
import logo from "../log.png";
import { Badge, CircularProgress } from "@mui/material";
import { Email, Lock, Person, ShoppingCart } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toggleDrawer } from "../slice/cartSlice";
import { setLoggedIn } from "../slice/loggedInSlice";

export default function Header({ route }) {
  const [open, setOpen] = useState(false);
  const [openlogin, setOpenlogin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [emailLogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const loggedin = useSelector((state) => state.login.isLoggedIn);
  const [username, setUsername] = useState("");
  const [userid, setUserid] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setOpenlogin(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setPassword("");
    setName("");
  };

  const handleLoginClick = () => {
    setOpenlogin(true); // Show login form
  };
  const handleSignupClick = () => {
    setOpenlogin(false); // Show login form
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const validatePassword = (password) => {
    if (password.length <= 8) {
      toast.error("Password should be longer than 8 characters.");
      return false;
    }
    return true;
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    if (emailLogin.trim() === "" || passwordLogin.trim() === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!validateEmail(emailLogin) || !validatePassword(passwordLogin)) return;

    try {
      setLoading(true);
      const response = await axios.post(`${apiBaseUrl}login`, {
        email: emailLogin,
        password: passwordLogin,
      });
      console.log(response.data);

      if (response.data.message === "Login successful") {
        console.log(response.data);
        toast.success("Login successful!");
        setOpen(false);
        setLoading(false);
        const array = response.data.data.map((item) => item.username);
        const uid = response.data.data.map((item) => item.id);
        setUsername(array);
        setUserid(uid);
        dispatch(setLoggedIn(true));
        localStorage.setItem("username", array);
        localStorage.setItem("userid", uid);
      } else {
        toast.error("An error occurred. Please try again.");
        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        toast.error(err.response.message);
      }
      setLoading(false);
    }
    setEmailLogin("");
    setPasswordLogin("");
  };

  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    if (name.trim() === "" || email.trim() === "" || password.trim() === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!validateEmail(email) || !validatePassword(password)) return;

    try {
      setLoading(true);
      const response = await axios.post(`${apiBaseUrl}signup`, {
        username: name,
        email: email,
        password: password,
      });

      if (response.data.message === "User registered successfully") {
        toast.success("Sign up successful!");
        const array = response.data.data.username;
        const uid = response.data.data.userId;
        console.log(array);
        setUsername(array);
        setUserid(uid);
        dispatch(setLoggedIn(true));
        localStorage.setItem("username", array);
        localStorage.setItem("userid", uid);
        setLoading(false);
      } else {
        toast.error("Sign up failed. Try again.");
        setLoading(false);
      }
    } catch (err) {
      toast.error("An error occurred. Please try again.");
      console.log(err);
      setLoading(false);
    }
    setName("");
    setEmail("");
    setPassword("");
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedUserid = localStorage.getItem("userid");
    if (storedUsername) {
      setUserid(storedUserid);
      setUsername(storedUsername);
      dispatch(setLoggedIn(true));
    }
  }, []);
  const dispatch = useDispatch();
  const handleLogout = () => {
    setUsername("");
    dispatch(setLoggedIn(false));
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
  };
  const handleCartClick = () => {
    dispatch(toggleDrawer(true));
  };
  const count = useSelector((state) => state.cart.count);

  const showShoppingCart = route === "/courses";

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
    if (event.target.value === "About Us") {
      setDropdownAnchor(event.currentTarget);
    }
    if (event.target.value === "Home") {
      window.location.href = "/";
    }
    if (event.target.value === "Help") {
      window.location.href = "/contact";
    }
    if (event.target.value === "Newsletter") {
      window.location.href = "/newsletter";
    }
    if (event.target.value === "Blog") {
      // toast.info("Blog is under development")
      window.location.href = "/blog";
    }
    if (event.target.value === "Courses") {
      // window.location.href = "/courses";
      toast.info("Courses is under development");
    }
  };
  const handleDropdownClose = () => {
    setDropdownAnchor(null); // Hide dropdown menu
  };
  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "transparent" }}>
      <ToastContainer
        position="top-right" // Set the position to top-left
        autoClose={3000} // Automatically close after 5 seconds
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            style={{ width: "50px", marginRight: 1 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <a href="/">
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </a>
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  sx={{ color: "#4F80A8" }}
                  key={page}
                  onClick={handleCloseNavMenu}
                >
                  <Button
                    value={page}
                    sx={{
                      fontFamily: "Urbanist",
                      color: "#4F80A8",
                      textAlign: "center",
                    }}
                  >
                    {page}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              gap: "30px",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                value={page}
                onClick={handleCloseNavMenu}
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  my: 2,
                  color: "#4F80A8",
                  display: "block",
                  fontFamily: "Urbanist",
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Menu
              anchorEl={dropdownAnchor}
              open={Boolean(dropdownAnchor)}
              onClose={handleDropdownClose}
              onMouseLeave={handleDropdownClose}
              MenuListProps={{ onMouseLeave: handleDropdownClose }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => (window.location.href = "/our-story")}>
                Our Story
              </MenuItem>
              <MenuItem
                onClick={() => (window.location.href = "/leadership-panel")}
              >
                Leadership Panel
              </MenuItem>
            </Menu>
            {showShoppingCart && (
              <IconButton
                sx={{ color: "#4F80A8", p: 0 }}
                onClick={handleCartClick}
              >
                <Badge color="primary" badgeContent={count}>
                  <ShoppingCart />
                </Badge>
              </IconButton>
            )}
            {loggedin ? (
              <>
                <Tooltip title={username}>
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ color: "#4F80A8", p: 0 }}
                  >
                    <Avatar />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <a onClick={handleLogout}>Logout</a>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  sx={{
                    backgroundColor: "#4F80A8",
                    color: "#fff",
                    borderRadius: "50px",
                    ":hover": {
                      backgroundColor: "blue",
                      color: "white",
                      borderRadius: "50px",
                    },
                  }}
                  onClick={handleOpen}
                >
                  Login / Signup
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Paper
                      elevation={3}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 400,
                        p: 3,
                        backgroundColor: "#FFF",
                        borderRadius: 2,
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {/* Centered logo */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: 2, // margin-bottom to create space between the image and the form
                        }}
                      >
                        <img src={logo} alt="logo" style={{ width: "60px" }} />
                      </Box>

                      {/* Conditional rendering for Login or Signup */}
                      {openlogin ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start", // Align items to the left
                            justifyContent: "center",
                            color: "#4F80A8",
                          }}
                        >
                          <TextField
                            value={emailLogin}
                            onChange={(e) => setEmailLogin(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{
                              input: { color: "#4F80A8" },
                              bgcolor: "#F0F0F0",
                              borderRadius: 1,
                            }}
                            placeholder="Enter your email"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Email sx={{ color: "#4F80A8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            value={passwordLogin}
                            onChange={(e) => setPasswordLogin(e.target.value)}
                            type="password"
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{
                              input: { color: "#4F80A8" },
                              bgcolor: "#F0F0F0",
                              borderRadius: 1,
                            }}
                            placeholder="Enter your password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock sx={{ color: "#4F80A8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />

                          {/* Centered submit button */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              type="submit"
                              onClick={handleSubmitLogin}
                              sx={{
                                mt: 2,
                                backgroundColor: "#4F80A8",
                                color: "#FFF",
                                "&:hover": {
                                  backgroundColor: "#1A73E8",
                                },
                              }}
                            >
                              Submit
                            </Button>
                          </Box>

                          <Link
                            onClick={handleSignupClick}
                            sx={{
                              mt: 2,
                              color: "#4F80A8",
                              cursor: "pointer",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            Sign Up
                          </Link>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start", // Align items to the left
                            justifyContent: "center",
                            color: "#4F80A8",
                          }}
                        >
                          <TextField
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{
                              input: { color: "#4F80A8" },
                              bgcolor: "#F0F0F0",
                              borderRadius: 1,
                            }}
                            placeholder="Enter your name"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Person sx={{ color: "#4F80A8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />

                          <TextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{
                              input: { color: "#4F80A8" },
                              bgcolor: "#F0F0F0",
                              borderRadius: 1,
                            }}
                            placeholder="Enter your email"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Email sx={{ color: "#4F80A8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />

                          <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            fullWidth
                            margin="normal"
                            size="small"
                            sx={{
                              input: { color: "#4F80A8" },
                              bgcolor: "#F0F0F0",
                              borderRadius: 1,
                            }}
                            placeholder="Enter your password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Lock sx={{ color: "#4F80A8" }} />
                                </InputAdornment>
                              ),
                            }}
                          />

                          {/* Centered submit button */}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              type="submit"
                              onClick={handleSubmitSignup}
                              sx={{
                                mt: 2,
                                backgroundColor: "#4F80A8",
                                color: "#FFF",
                                "&:hover": {
                                  backgroundColor: "#1A73E8",
                                },
                              }}
                            >
                              Submit
                            </Button>
                          </Box>

                          <Typography
                            variant="h6"
                            sx={{
                              mt: 2,
                              color: "#4F80A8",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            Already a user?
                          </Typography>
                          <Link
                            onClick={handleLoginClick}
                            sx={{
                              mt: 1,
                              color: "#4F80A8",
                              cursor: "pointer",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            Login
                          </Link>
                        </Box>
                      )}
                    </Paper>
                  )}
                </Modal>{" "}
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
