import { Box,Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { memberinfo } from '../constants'
export default function About() {
  return (
    <div>
  <Box
    sx={{
     
      color: 'white',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <Box
  sx={{
    backgroundColor: '#F0F4FF',
    color: 'white',
    minHeight: '100vh',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
>
<Box>
{/* <Typography variant='h4' sx={{ mb:2,color: '#4F80A8',textAlign:"center",fontWeight:"bold"}}>Meet Our Leadership Panel</Typography> */}

  <Grid container spacing={4} justifyContent="center" >
    
    {memberinfo.map((item, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Card
          sx={{
            maxWidth: 345,
            margin: '0 auto',
            backgroundColor: 'white',
            color: '#08024F',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
          }}
        >
          <CardMedia
            sx={{
              height: 200,
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
            }}
            image={item.image}
            title={item.name}
          />
          <CardContent sx={{ padding: '16px' }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Urbanist',
              }}
            >
              {item.name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Urbanist',
                color: '#4F80A8',
                marginBottom: '8px',
              }}
            >
              {item.designaion}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Urbanist',
                color: '#1A73E8',
              }}
            >
              {item.about}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
    
</Box>
</Box>

  </Box>
</div>

  )
}