import { SvgIcon } from '@mui/material';

function BrainIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-2.67 1.3-5.16 3.36-6.66.66-.5 1.42-.84 2.28-.84 1.76 0 3.24 1.09 3.81 2.61.51-1.5 1.94-2.61 3.69-2.61 1.57 0 2.9.78 3.66 1.97 1.61 1.45 2.67 3.43 2.67 5.68 0 4.41-3.59 8-8 8zm-4.29-5.21c-.44.43-.72.94-.72 1.52 0 1.1.9 2 2 2 .54 0 1.04-.21 1.41-.59.54-.53.67-1.3.33-1.98-.27-.5-.8-.81-1.36-.81-.55 0-1.09.31-1.39.76-.08.14-.24.21-.37.21-.28 0-.5-.22-.5-.5s.22-.5.5-.5c.26 0 .52.1.71.28.15.14.35.22.56.22.27 0 .5-.22.5-.5s-.22-.5-.5-.5c-.54 0-1.09.23-1.46.68-.32.39-.5.89-.5 1.42 0 .27.22.5.5.5.12 0 .24-.05.33-.14zm2.79.21c.55 0 1.09-.31 1.39-.76.08-.14.24-.21.37-.21.28 0 .5.22.5.5s-.22.5-.5.5c-.26 0-.52-.1-.71-.28-.15-.14-.35-.22-.56-.22-.27 0-.5.22-.5.5s.22.5.5.5c.54 0 1.09-.23 1.46-.68.32-.39.5-.89.5-1.42 0-.27-.22-.5-.5-.5-.12 0-.24.05-.33.14-.44.43-.72.94-.72 1.52 0 1.1.9 2 2 2 .54 0 1.04-.21 1.41-.59.54-.53.67-1.3.33-1.98-.27-.5-.8-.81-1.36-.81-.55 0-1.09.31-1.39.76-.08.14-.24.21-.37.21-.28 0-.5-.22-.5-.5s.22-.5.5-.5c.26 0 .52.1.71.28.15.14.35.22.56.22zm-6.17-2.17c.49.43.82 1.04.82 1.73 0 1.1-.9 2-2 2-.54 0-1.04-.21-1.41-.59-.54-.53-.67-1.3-.33-1.98.27-.5.8-.81 1.36-.81.55 0 1.09.31 1.39.76.08.14.24.21.37.21.28 0 .5-.22.5-.5s-.22-.5-.5-.5c-.26 0-.52.1-.71.28-.15.14-.35.22-.56.22-.27 0-.5-.22-.5-.5s.22-.5.5-.5c.54 0 1.09.23 1.46.68.32.39.5.89.5 1.42 0 .27-.22.5-.5.5-.12 0-.24-.05-.33-.14-.44-.43-.72-.94-.72-1.52 0-1.1.9-2 2-2 .54 0 1.04.21 1.41.59.54.53.67 1.3.33 1.98-.27.5-.8.81-1.36.81-.55 0-1.09-.31-1.39-.76-.08-.14-.24-.21-.37-.21-.28 0-.5.22-.5.5s.22.5.5.5c.26 0 .52-.1.71-.28.15-.14.35-.22.56-.22z" />
    </SvgIcon>
  );
}

export default BrainIcon;
